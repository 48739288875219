import * as React from 'react';
import {
    AnyObject,
    IComponentViewController,
    IViewManager,
    root
} from '@estee/elc-universal-utils';
import {
    IComponentDataState,
    IComponentState,
    initializeControllerForSSR
} from '@estee/elc-service';
import { ELCLogger, TriggerType } from '@estee/elc-logging';
import { ErrorBoundary } from '@estee/elc-telemetry';
import { ViewDataPreloader, IConfigsCollection } from '../preloader/ViewDataPreloader';
import { LazyRenderer } from './LazyRenderer';

export type ComponentNodeType = {
    name: string;
    config: string | null;
    component: React.ReactElement | null;
    node: HTMLElement;
    state: string | null;
};

interface IComponentPropsFunctions {
    [key: string]: Function | object | null;
}

interface IComponentProps extends IComponentPropsFunctions {
    config: object;
    viewController: IComponentViewController | null;
    parentMountPointNode: Element;
}

const { name: serviceName, version } = __serviceInfo__;

export abstract class ComponentRenderer extends LazyRenderer {
    private preloader: ViewDataPreloader | null = null;

    protected readonly logger: ELCLogger = new ELCLogger({
        serviceName,
        serviceVersion: version,
        environment: root.env,
        buid: root.buid
    });

    private handleEventsFromProps = (node: HTMLElement, parsedConfig: AnyObject) =>
        Object.keys(parsedConfig)
            .filter((configKey) => parsedConfig[configKey] === '__event__')
            .reduce((eventHandlers, configKey) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const eventHandler = function (...args: any[]) {
                    const event = new CustomEvent(configKey, {
                        detail: args,
                        cancelable: true,
                        bubbles: false
                    });
                    node.dispatchEvent(event);
                };

                return { ...eventHandlers, [configKey]: eventHandler };
            }, {});

    private getConfig = async (component: ComponentNodeType) => {
        let configs: IConfigsCollection | null = null;
        // Don't overload components for non exported views otherwise will crash
        if (this.getNodeAttribute(component.node, 'data-version')) {
            if (this.preloader) {
                await this.preloader.preload();
                configs = this.preloader.getPreloadedConfigs();
            }
        }

        return { configs };
    };

    private getElementProps = async (
        component: ComponentNodeType,
        viewManager: IViewManager
    ): Promise<IComponentProps> => {
        const parsedConfig = component.config ? JSON.parse(component.config) : {};
        const viewController = viewManager.controllerFromView;

        const { configs } = await this.getConfig(component);
        const fnProps = this.handleEventsFromProps(component.node, parsedConfig);

        return {
            ...fnProps,
            config: { ...configs, ...parsedConfig },
            parentMountPointNode: component.node.parentNode as Element,
            viewController
        };
    };

    private getElementToRender = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        registeredComponent: React.ComponentType<any>,
        props: IComponentProps,
        viewName: string
    ) => {
        return React.createElement(ErrorBoundary, {
            serviceName,
            name: viewName,
            children: React.createElement(registeredComponent, props)
        });
    };

    private handleController = (
        viewController: IComponentViewController | null,
        state: IComponentDataState,
        config: string | null
    ) => {
        try {
            initializeControllerForSSR(viewController, state, config);
        } catch (error) {
            this.logger.error({
                triggerType: TriggerType.render,
                message: error.message,
                payload: {
                    error,
                    context: {
                        name
                    }
                }
            });
        }
    };

    private parseState = (state: string | null) => {
        let parsedState: IComponentState | undefined;
        try {
            if (state) {
                parsedState = JSON.parse(state);
            }
        } finally {
            parsedState = parsedState || { children: [] };
        }

        return parsedState;
    };

    private processChildrenViewNames = async (nodeState: string | null) => {
        const { children: childrenViewNames = [], ...state } = this.parseState(nodeState);

        try {
            await Promise.all(
                childrenViewNames.map(async (viewName: string) => {
                    const viewManager = root.ViewsManager.getViewManager(viewName);
                    await viewManager.loadComponent();
                })
            );
        } catch (error) {
            this.logger.error({
                triggerType: TriggerType.render,
                message: `Failed to load views requested for SSR: ${childrenViewNames.join(', ')}`,
                payload: {
                    error
                }
            });
        }

        return state;
    };

    protected getComponentForNode = async (node: ComponentNodeType) => {
        const nodeObject = { ...node };

        const viewManager = root.ViewsManager.getViewManager(node.name);
        await viewManager.loadComponent();
        const module = viewManager.module;
        if (module) {
            const props = await this.getElementProps(nodeObject, viewManager);
            nodeObject.component = this.getElementToRender(module, props, nodeObject.name);

            // SSR
            const state = await this.processChildrenViewNames(nodeObject.state);
            this.handleController(props.viewController, state, nodeObject.config);
        }

        return Promise.resolve(nodeObject);
    };

    protected convertNodeToComponentNodeType = (node: HTMLElement) => ({
        component: null,
        config: this.getNodeAttribute(node, 'data-config'),
        name: this.getNodeAttribute(node, 'data-component') || '',
        node,
        state: this.getNodeAttribute(node, 'data-state')
    });

    public setDataPreloader = (preloader: ViewDataPreloader) => {
        this.preloader = preloader;
    };

    public getDataPreloader = () => {
        return this.preloader;
    };
}
