(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react-dom"), require("ElcServiceBus"), require("ELCLogger"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["react-dom", "ElcServiceBus", "ELCLogger", "react"], factory);
	else if(typeof exports === 'object')
		exports["elc-component-renderer"] = factory(require("react-dom"), require("ElcServiceBus"), require("ELCLogger"), require("react"));
	else
		root["elc-component-renderer"] = factory(root["ElcRuntime"]["ReactDom"], root["ElcServiceBus"], root["ElcRuntime"]["ELCLogger"], root["ElcRuntime"]["React"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_react_dom__, __WEBPACK_EXTERNAL_MODULE__estee_elc_service_bus__, __WEBPACK_EXTERNAL_MODULE__estee_elc_logging__, __WEBPACK_EXTERNAL_MODULE_react__) => {
return 