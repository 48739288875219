import { root } from '@estee/elc-universal-utils';
import { debounceAsync } from '../utils/Debounce';

export interface ITranslationsCollection {
    [key: string]: string;
}

export interface IConfigsCollection {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export class ViewDataPreloader {
    private translationsCache: ITranslationsCollection = {};
    private translationsFieldsToPreload: string[] = [];

    private configsCache: IConfigsCollection = {};
    private configFieldsToPreload: string[] = [];

    private getKeysFromObject(keys: string[], obj: IConfigsCollection | ITranslationsCollection) {
        const requestedObject: IConfigsCollection | ITranslationsCollection = {};

        const isKeyMissing: boolean = keys.some((key) => {
            const hasKeyInCache: boolean = obj.hasOwnProperty(key);
            if (hasKeyInCache) {
                requestedObject[key] = obj[key];
            }

            return !hasKeyInCache;
        });

        return !isKeyMissing ? requestedObject : null;
    }

    public getPreloadedConfigs = (fields: string[] = []) => {
        if (fields.length === 0) {
            return this.configsCache;
        }

        return this.getKeysFromObject(fields, this.configsCache);
    };

    public getPreloadedTranslations = (fields: string[] = []) => {
        if (fields.length === 0) {
            return this.translationsCache;
        }

        return this.getKeysFromObject(fields, this.translationsCache);
    };

    public addTranslations = (translations: ITranslationsCollection) => {
        this.translationsCache = {
            ...(this.translationsCache || {}),
            ...(translations || {})
        };
    };

    public registerTranslationFieldsToPreload = (fields: string[] = []) => {
        this.translationsFieldsToPreload = [
            ...new Set(this.translationsFieldsToPreload.concat(fields))
        ];
        this.preload();
    };

    public addConfigs = (configs: object) => {
        this.configsCache = {
            ...(this.configsCache || {}),
            ...(configs || {})
        };
    };

    public registerConfigFieldsToPreload = (fields: string[] = []) => {
        this.configFieldsToPreload = [...new Set(this.configFieldsToPreload.concat(fields))];
        this.preload();
    };

    public preload = debounceAsync(async () => {
        const translations = this.translationsFieldsToPreload;
        const configs = this.configFieldsToPreload;

        if (!this.getPreloadedTranslations(this.translationsFieldsToPreload)) {
            const trans = await root.GlobalServiceBus.query(
                'get.translations',
                this.translationsFieldsToPreload
            );
            this.addTranslations(trans);
            this.translationsFieldsToPreload = this.translationsFieldsToPreload.filter(
                (tr) => !translations.includes(tr)
            );
        }
        if (!this.getPreloadedConfigs(this.configFieldsToPreload)) {
            const cfg = await root.GlobalServiceBus.query('get.config', this.configFieldsToPreload);
            this.addConfigs(cfg);
            this.configFieldsToPreload = this.configFieldsToPreload.filter(
                (cg) => !configs.includes(cg)
            );
        }
    }, 100);
}
