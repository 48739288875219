/* eslint-disable no-restricted-syntax */
import { root, setupServiceInfo } from '@estee/elc-universal-utils';
import { BrowserComponentRenderer } from './BrowserComponentRenderer';
import { ViewDataPreloader } from './preloader/ViewDataPreloader';

setupServiceInfo(__serviceInfo__);

if (root && !root.ComponentRenderer) {
    root.ComponentRenderer = new BrowserComponentRenderer();
    const preloader = new ViewDataPreloader();
    root.registerConfigFieldsToPreload =
        root.registerConfigFieldsToPreload || preloader.registerConfigFieldsToPreload;
    root.getPreloadedConfigs = root.getPreloadedConfigs || preloader.getPreloadedConfigs;
    root.registerTranslationFieldsToPreload =
        root.registerTranslationFieldsToPreload || preloader.registerTranslationFieldsToPreload;
    root.getPreloadedTranslations =
        root.getPreloadedTranslations || preloader.getPreloadedTranslations;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (root.ComponentRenderer as any).setDataPreloader(preloader);
}

export { BrowserComponentRenderer };
