import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { emit, events } from '@estee/elc-service-bus';
import { Breakpoints } from '@estee/elc-base-theme';
import { TriggerType } from '@estee/elc-logging';
import { MOBILE_VERSION_SELECTOR, DESKTOP_VERSION_SELECTOR } from '@estee/elc-universal-utils';
import { removeAllNodesBySelector, cleanseDOM } from './utils/DOMCleanser';
import { ComponentRenderer, ComponentNodeType } from './renderers';

const COMPONENT_SELECTOR = '[data-component]';

type PrimiteTypes = number | boolean | object | null | string;
interface IComponentLoaded {
    shouldRender: boolean;
    config: PrimiteTypes | PrimiteTypes[];
}

interface IComponentsLoaded {
    [key: string]: IComponentLoaded[];
}

interface IRenderParams {
    component: React.ReactElement;
    node: HTMLElement;
    isSSR: boolean;
    name: string;
}

export class BrowserComponentRenderer extends ComponentRenderer {
    private nodesToRenderList: Set<HTMLElement> = new Set([]);
    private isRenderReadyPromise: Promise<void>;
    public isRenderReady = () => this.isRenderReadyPromise;

    protected getNodeAttribute = (node: HTMLElement, name: string) => node.getAttribute(name);

    protected isServiceViewNode = (element: HTMLElement): boolean =>
        element.matches(COMPONENT_SELECTOR);

    protected getServiceViewsFromNode = (node: HTMLElement) =>
        node.querySelectorAll(COMPONENT_SELECTOR) as NodeListOf<HTMLElement>;

    private isElement = (node: Node) => node.nodeType === Node.ELEMENT_NODE;

    private addNodeToRenderList = (nodes: Set<HTMLElement>, nodeToAdd: HTMLElement) => {
        if (!this.nodesToRenderList.has(nodeToAdd)) {
            nodes.add(nodeToAdd);
            this.nodesToRenderList.add(nodeToAdd);
        }
    };

    private removeNodeFromRenderList = (nodeToRemove: HTMLElement) => {
        if (this.nodesToRenderList.has(nodeToRemove)) {
            this.nodesToRenderList.delete(nodeToRemove);
        }
    };

    private processMutationRecord = (nodes: Set<HTMLElement>, record: MutationRecord) => {
        record.addedNodes.forEach((node: HTMLElement) => {
            if (this.isElement(node)) {
                const serviceViews = this.isServiceViewNode(node)
                    ? [node]
                    : this.getServiceViewsFromNode(node);

                serviceViews.forEach((view: HTMLElement) => this.addNodeToRenderList(nodes, view));
            }
        });

        // If a ServiceView is removed from DOM we need to remove also the reference to it
        record.removedNodes.forEach(this.removeNodeFromRenderList);

        return nodes;
    };

    private onHTMLChanged = async (records: MutationRecord[]) => {
        const addedNodes = records.reduce(this.processMutationRecord, new Set<HTMLElement>());

        await this.renderReactNodes([...addedNodes]);
    };

    private createMutationObserver = (callback: MutationCallback) => {
        if (typeof MutationObserver !== 'undefined') {
            return new MutationObserver(callback);
        }

        return null;
    };

    private watchRenderOutput() {
        const mutationObserverComponent = this.createMutationObserver(this.onHTMLChanged);
        if (mutationObserverComponent) {
            mutationObserverComponent.observe(document.body, {
                subtree: true,
                childList: true
            });
        }
    }

    private render = ({ component, node, isSSR, name }: IRenderParams) => {
        if (isSSR) {
            return new Promise<void>((resolve, reject) => {
                try {
                    ReactDOM.hydrate(component, node, resolve);
                } catch (error) {
                    this.logger.error({
                        message: `Failed to ssh render view ${name}`,
                        triggerType: TriggerType.render,
                        payload: {
                            error
                        }
                    });
                    reject(node);
                }
            });
        } else {
            return new Promise<void>((resolve, reject) => {
                try {
                    ReactDOM.render(component, node, resolve);
                } catch (error) {
                    this.logger.error({
                        message: `Failed to render view ${name}`,
                        triggerType: TriggerType.render,
                        payload: {
                            error
                        }
                    });
                    reject(node);
                }
            });
        }
    };

    private renderNode = async (node: HTMLElement) => {
        try {
            const componentNode = this.convertNodeToComponentNodeType(node);
            const nodeToRender: ComponentNodeType = await this.getComponentForNode(componentNode);

            if (nodeToRender.component) {
                const { component, state, name } = nodeToRender;

                const renderPromise = this.render({
                    component,
                    node: nodeToRender.node,
                    isSSR: Boolean(state),
                    name
                });

                return renderPromise;
            }
        } catch (error) {
            this.logger.error({
                triggerType: TriggerType.render,
                message: 'Error trying to render node',
                payload: {
                    error
                }
            });
        }

        return Promise.resolve(node);
    };

    private renderReactNodes = async (nodes: Element[]) => {
        const renderedNodes = (await Promise.all(
            nodes.map(this.renderNode)
        )) as (null | HTMLElement)[];

        const pendingNodes = renderedNodes.filter(Boolean) as Element[];

        if (pendingNodes.length > 0) {
            this.setTimeoutRenderingNodes(pendingNodes);
        }

        return Promise.resolve();
    };

    private setTimeoutRenderingNodes = async (nodes: Element[]) => {
        setTimeout(async () => {
            await this.renderReactNodes(nodes);
        }, 250);
    };

    private renderLazyNode = (node: HTMLElement) => {
        if (!this.nodesToRenderList.has(node)) {
            this.nodesToRenderList.add(node);
            this.renderReactNodes([node]);
        }
    };

    private processMountPoints = async () => {
        const allNodes: NodeListOf<HTMLElement> = document.querySelectorAll(COMPONENT_SELECTOR);

        const toRenderNodes = [...allNodes].filter(this.shouldRenderNode);
        this.nodesToRenderList = new Set(toRenderNodes);

        await this.renderReactNodes(toRenderNodes);

        if (toRenderNodes.length < allNodes.length) {
            this.initLazyRenderer(this.renderLazyNode);
        }

        this.announceMountPoints([...allNodes]);
    };

    private announceMountPoints = (nodes: HTMLElement[]) => {
        const loadedComponents = nodes.reduce(
            (components: IComponentsLoaded, node: HTMLElement) => {
                try {
                    const { component, config } = node.dataset;

                    if (component) {
                        components[component] = components[component] || [];

                        components[component].push({
                            shouldRender: this.nodesToRenderList.has(node),
                            config: JSON.parse(config || '{}')
                        });
                    }
                } catch (error) {
                    this.logger.error({
                        triggerType: TriggerType.render,
                        message: 'Failed parsing config',
                        payload: {
                            error
                        }
                    });
                }

                return components;
            },
            {} as IComponentsLoaded
        );

        emit(events.COMPONENTS_LOADED, loadedComponents);
    };

    private onDOMContentLoaded = () => {
        this.isRenderReadyPromise = this.processMountPoints();
        // Remove after SDFEFND-1853
        // ServiceViews up from 5.x doesn't depend on the Component Renderer anymore
        // We could remove the MutationObserver if we could upgrade all brands to use this newer version
        this.watchRenderOutput();
    };

    private cleanUpMarkup = () => {
        const isDesktop = window.matchMedia(`(min-width: ${Breakpoints.desktop}px)`).matches;
        if (isDesktop) {
            removeAllNodesBySelector(`.${MOBILE_VERSION_SELECTOR}`);
            cleanseDOM(`.${DESKTOP_VERSION_SELECTOR}`);
        } else {
            removeAllNodesBySelector(`.${DESKTOP_VERSION_SELECTOR}`);
            cleanseDOM(`.${MOBILE_VERSION_SELECTOR}`);
        }
    };

    public init = async () => {
        const isDocumentReady = ['complete', 'interactive'].includes(document.readyState);
        this.cleanUpMarkup();

        if (isDocumentReady) {
            this.onDOMContentLoaded();
        } else {
            await new Promise<void>((resolve) =>
                document.addEventListener('DOMContentLoaded', () => {
                    this.onDOMContentLoaded();
                    resolve();
                })
            );
        }

        await this.isRenderReady();
    };
}
