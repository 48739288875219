/* eslint-disable @typescript-eslint/no-explicit-any */
type AnyFn = (...rest: any[]) => Promise<any>;

export const debounceAsync = (fn: AnyFn, ms: number) => (...args: any[]) => {
    let timer: any = null;

    if ('_timer_' in fn) {
        timer = (fn as any)._timer_;
        clearTimeout(timer.id);
        timer.id = setTimeout(timer.resolve, ms);

        return timer.promise;
    }

    timer = {
        promise: Promise.resolve(),
        resolve: () => {},
        id: null
    };
    timer.promise = new Promise((resolve, _) => {
        timer.resolve = () => {
            delete (fn as any)._timer_;
            resolve(fn(...args));
        };
        (timer as any).id = setTimeout(timer.resolve, ms);
    });
    (fn as any)._timer_ = timer;

    return timer.promise;
};
