export const cleanseDOM = (selector: string) => {
    const wrappers = document.querySelectorAll(selector);

    wrappers.forEach(wrapper => {
        wrapper.replaceWith(...wrapper.childNodes);
    });
};

export const removeAllNodesBySelector = (selector: string) => {
    document.querySelectorAll(selector).forEach(node => node.remove());
};
