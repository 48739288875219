import { ELCLogger, TriggerType } from '@estee/elc-logging';

enum RenderStrategies {
    default = 'default',
    ondemand = 'ondemand',
    serverside = 'serverside'
}

export type OnDemandRenderOffset = { onDemandRenderOffset?: number };
export type RenderStrategiesTypes = keyof typeof RenderStrategies;

enum ObservedValues {
    parent = 'parent',
    node = 'node',
    none = 'none'
}

type PendingNodes = {
    parent: HTMLElement;
    node: HTMLElement;
    isObserved: ObservedValues;
};

export abstract class LazyRenderer {
    protected abstract getNodeAttribute(node: HTMLElement, name: string): string | null;
    protected abstract isServiceViewNode(element: HTMLElement): boolean;
    protected abstract getServiceViewsFromNode(node: HTMLElement): NodeListOf<HTMLElement>;
    protected abstract readonly logger: ELCLogger;

    private intersectionObserver: IntersectionObserver;
    private viewHeight: number;
    private ALLOWED_PIXELS = 200;
    private pendingNodes: Set<PendingNodes> = new Set([]);

    private updateViewHeight = () => {
        this.viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    };

    private isInViewport = (node: Element, customMargin = this.ALLOWED_PIXELS): boolean => {
        const { bottom, top } = node.getBoundingClientRect();
        if (!this.viewHeight) {
            this.updateViewHeight();
        }
        // To load before get's into screen (scrolling down)
        const isBottomLessThanViewport = bottom < this.viewHeight + customMargin;
        // Check if its on viewport or close to it
        const isBottomInViewport = bottom > -customMargin;

        const isBottomVisible = isBottomLessThanViewport && isBottomInViewport;

        // In case it is scrolling up to start rendering
        const isTopVisible = top + customMargin > 0 && top < this.viewHeight;

        // In case node is higher than viewport
        const isHigherThanViewHeight = bottom < 0 && top > this.viewHeight;

        return isBottomVisible || isTopVisible || isHigherThanViewHeight;
    };

    private getRenderMethodStrategy = (node: HTMLElement) =>
        <RenderStrategiesTypes>this.getNodeAttribute(node, 'data-render-method') ||
        RenderStrategies.default;

    private getRenderConfig = (node: HTMLElement) =>
        this.getNodeAttribute(node, 'data-render-config') || '{}';

    private isNodeInViewport = (node: HTMLElement): boolean => {
        const parent = node.parentNode as HTMLElement;

        try {
            const config = this.getRenderConfig(node);

            const { onDemandRenderOffset }: { onDemandRenderOffset?: number } = JSON.parse(config);

            if (!parent ||
                (
                    !this.isInViewport(node, onDemandRenderOffset) &&
                    !this.isInViewport(parent, onDemandRenderOffset)
                )
            ) {
                this.pendingNodes.add({ node, parent, isObserved: ObservedValues.none });

                return false;
            }

            return true;
        } catch (error) {
            console.error(error);

            return true;
        }
    };

    private isNodeSkeleton = (node: Element): boolean =>
        [...(node.classList || [])].some((className) => className.includes('skeleton'));

    private hasSkeletonsAsSiblings = (node: Element, checkNextSibling: boolean): boolean => {
        let currentNode = checkNextSibling ? node.nextElementSibling : node.previousElementSibling;
        let isSkeleton = false;

        while (currentNode) {
            isSkeleton = this.isNodeSkeleton(currentNode as Element);
            currentNode = checkNextSibling ?
                currentNode.nextElementSibling :
                currentNode.previousElementSibling;
        }

        return isSkeleton;
    };

    private hasOnlySkeletons = (node: Element): boolean => {
        const hasNextSibling = Boolean(node.nextSibling);
        const hasPreviousSibling = Boolean(node.previousSibling);

        if (hasNextSibling || hasPreviousSibling) {
            const isNextSiblingSkeletonOnly = this.hasSkeletonsAsSiblings(node, true);

            if (hasPreviousSibling && (isNextSiblingSkeletonOnly || hasNextSibling)) {
                const isPreviousSiblingSkeletonOnly = this.hasSkeletonsAsSiblings(node, false);

                return isPreviousSiblingSkeletonOnly;
            }

            return isNextSiblingSkeletonOnly;
        }

        return false;
    };

    public shouldRenderNode = (node: HTMLElement): boolean => {
        const renderStrategy: RenderStrategiesTypes = this.getRenderMethodStrategy(node);

        if (renderStrategy === RenderStrategies.ondemand) {
            return this.isNodeInViewport(node);
        }

        return true;
    };

    public initLazyRenderer = (onNodeDisplay: (node: Element) => void) => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0
        };

        const intersectionCallback = ([entry]: IntersectionObserverEntry[]) => {
            const target = entry.target as HTMLElement;

            const isServiceView = this.isServiceViewNode(target);
            const serviceViewNode = isServiceView
                ? target
                : this.getServiceViewsFromNode(target)[0];
            const parentNode = (isServiceView ? target.parentNode : target) as HTMLElement;

            const config = this.getRenderConfig(serviceViewNode);

            try {
                const { onDemandRenderOffset }: {
                    onDemandRenderOffset?: number
                } = JSON.parse(config);

                if (
                    entry.isIntersecting ||
                    (serviceViewNode &&
                        (this.isInViewport(serviceViewNode, onDemandRenderOffset) ||
                            this.isInViewport(parentNode, onDemandRenderOffset)))
                ) {
                    this.pendingNodes.forEach((nodes, _, set) => {
                        if (target.isSameNode(nodes.parent) || target.isSameNode(nodes.node)) {
                            set.delete(nodes);
                        }
                    });

                    this.intersectionObserver.unobserve(target);
                    // No more nodes to observe
                    if (this.pendingNodes.size === 0) {
                        this.intersectionObserver.disconnect();
                        // Setting the property as an optional `null` in the interface would add more checks and that could end up in a bug
                        // @ts-ignore
                        this.intersectionObserver = null;
                    }
                    onNodeDisplay(serviceViewNode);
                }
            } catch (error) {
                console.error(error);
                this.logger.error({
                    message: error.message,
                    triggerType: TriggerType.render,
                    payload: error
                });
            }
        };

        this.intersectionObserver = new IntersectionObserver(intersectionCallback, options);
        this.pendingNodes.forEach((pendingNode) => {
            const { node, parent } = pendingNode;

            if (node.getBoundingClientRect().height === 0) {
                (node as HTMLElement).style.minHeight = '1px';
            }

            const hasSkeletons = this.hasOnlySkeletons(node);

            pendingNode.isObserved = hasSkeletons ? ObservedValues.parent : ObservedValues.node;

            this.intersectionObserver.observe(hasSkeletons ? parent : node);
        });
    };
}
